<template>
  <div @click="liveJumpRed" v-if="liveRedPacket">
    <img style="position: fixed;right: 0;z-index: 99;width: 14%;" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/3e34474e39e0a6be176f27a7bfa10353.png" alt="红包雨">
  </div>
  <teleport to="body">

    <!-- 抽奖2.0  start-->
    <div class="luckey-draw" id="lottery2" style="display: none;">
      <div class="luckey-drawdiv">
        <div class="tips-box tips-box-start" style="display: none;">
          <p class="tips-title">提示</p>
          <div class="tips-content">
            <p class="tips-desc tips-start-desc">本次抽奖已取消</p>
          </div>
          <div class="tips-btn">
            <button class="tips-submit tips-start-submit">
              确定
            </button>
          </div>
        </div>
        <div class="luckey-drawing">
          <div  class="lottery_wrap lottery_wrap_ing">
            <div  class="lottery_content">
              <div class="bg_box bg-box-no">
              </div>
            </div>
            <div  class="height-scroll">
              <div  class="lottery_title_img">
                <img  src="//static.csslcloud.net/view_live/v1.9.0/assets/img/mobile/lottery/lottery-text_cn_a6fb5613.png" alt="">
              </div>
              <div  class="lottery_img">
                <img  src="//static.csslcloud.net/view_live/v1.9.0/assets/img/mobile/lottery/lottery-gift_1ecd1ce5.gif" alt="">
              </div>
            </div>
            <div  class="lottery_close luckey-drawing-close">
              <img  src="//static.csslcloud.net/view_live/v1.9.0/assets/img/mobile/lottery2/lottery-close@2x_7782dc34.png" alt="">
            </div>
          </div>

        </div>
        <div class="luckey-draw-result">
          <div class="luckey-draw-result-h3">抽奖结果</div>
          <div class="luckey-draw-result-close"></div>
          <div class="luckey-draw-content">
            <div class="win-prize-box">
              <p class="win-prize-tips">恭喜您获得了<span class="win-name"></span>,请牢记您的中奖码
              </p>
              <div class="win-prize-num"></div>
            </div>
            <div class="not-win-prize-box">
              <div class="not-win-prize"><img src="https://static.csslcloud.net/img/cry-face.png"></div>
              <p class="not-win-prize-tips">很遗憾，您没有获得<span class="no-win-name"></span></p>
            </div>
            <div class="win-prize-wrap">
              <div class="winning-list-title">中奖名单<span class="winning-list-icon up"></span></div>
              <ul class="win-prize-list"></ul>
            </div>
          </div>
        </div>
      </div>
      <div class="maskbg"></div>
    </div>
    <!-- 抽奖2.0  end-->

    <div class="modal" v-show="visiblePic">
      <div class="modal-header">温馨提示</div>
      <div class="modal-content">
        <div style="text-align: left;margin-bottom: 10px;">好友通过您分享的海报进入直播间并登录，视为邀请成功。 请长按下方图片保存</div>

        <img :src="codeImg" alt="" style="width: 100%;height: 100%;">
      </div>
      <div class="modal-footer">
        <div @click="closePic">取消</div>
      </div>
    </div>
    <div v-show="visiblePic" class="toast-modal"></div>

  </teleport>

  <div class="vrt-wrap">
    <div class="vrt-main-video" v-if="live.liveStatus == 3 && errorMsgStr != 'NO_RECORD'">
      <!-- 录播 -->
      <div id="playbackPanel"></div>
      <p id="countDown"></p>
    </div>
    <div class="vrt-main-video" v-else-if="!uid && live.liveStatus  != 3">
      <div @click="toLogin()"  style="height: 100%">
        <img  style="width:100%;height: 100%" src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/e1573aa9006ec125e7f11df7c4de117c.png">
      </div>
    </div>
    <div class="vrt-main-video" v-else>
      <!-- //直播 -->
      <div id="drawPanel"></div>
      <p class="countDown"></p>
      <div class="live-player-video">
        <video
            src="http://1300465694.vod2.myqcloud.com/8dd03868vodtransbj1300465694/c6366e31387702296103583426/v.f40.mp4"
            id="livePlayerVideo"
            loop
            autoplay
            controls
            poster="http://1300465694.vod2.myqcloud.com/b6e64ac0vodbj1300465694/c6366e31387702296103583426/387702296103583433.jpg"
        ></video>
      </div>
    </div>
    <div class="vrt-main-content" v-if="liveSwitchTab">
      <div class="vrt-main-warp">
        <div class="vrt-tab-warp">
          <div class="vrt-main-tab">
            <div class="swiper-container" id="menu-nav-swiper">
              <ul class="swiper-wrapper">
                <li
                    class="swiper-slide"
                    :class="{ current: itemBool == index, 'current-view': index == 1, 'five-dot': index === 2 }"
                    v-for="(item, index) in menu"
                    :key="index"
                    @click="selectMenu(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

          </div>
          <div class="vrt-main-tab-content">
            <div class="vrt-main-tab-first" v-show="itemBool == 0">
              <div class="video-info">
                <template v-if="live.liveStatus == 0">
                  <div
                      class="count_down count_down_time"
                      v-if="intro.liveStartTime"
                      id="dateShow"
                  >
                    <p>直播倒计时</p>
                    <span class="d">07</span>天 <span class="h">22</span>小时
                    <span class="m">50</span>分 <span class="date-s s">56</span>秒

                    <div @click="followClick"  :class="[showCode ? 'sign' : 'unsign']">{{showText}}</div>
                  </div>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <template v-if="live.liveStatus == 1">
                  <span class="count_down"><span>直播进行中</span></span>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <template v-if="live.liveStatus == 2">
                  <div class="count_down count_down_time" id="dateShow">
                    <p>直播倒计时</p>
                    <span class="d"></span>天 <span class="h"></span>小时
                    <span class="m"></span>分 <span class="date-s s"></span>秒
                    <div @click="followClick"  :class="[showCode ? 'sign' : 'unsign']">{{showText}}</div>
                  </div>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <template v-if="live.liveStatus == 3">
                  <span class="count_down live-end"><span>直播已结束</span></span>
                  <div class="count_down">
                    <span>{{ intro.courseName}}</span>
                  </div>
                </template>
                <class-intro :intro="intro"></class-intro>
              </div>

              <div class="vrt-first-content">
                <div class="course-description">
                  <class-sch-card :courseChapterList="courseChapterList" :isLogin="(uid!=null && uid >0)?true:false"></class-sch-card>
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-second" v-show="itemBool == 1">
              <div class="vrt-talk-list">

                <div class="vrt-radio-list">
                  <div class="vrt-radio-icon">
                    <div>
                      <img src="@/assets/img/liveRoom/horn.png" alt="">
                    </div>
                    <span class="vrt-radio-close">×</span>
                  </div>
                  <div class="vrt-radio-content"></div>
                </div>

                <div class="vrt-talk-list-ul vrt-second-list">
                  <ul>
                    <li>
                      <div class="vrt-talk-logo">
                        <img
                            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/picman.jpg?x-oss-process=image/resize,w_200"
                            alt="北纳生物网站地图，北京北方北纳生物技术研究院，课堂"
                        />
                      </div>
                      <div class="vrt-talk-box">
                        <p class="vrt-talk-info">
                          <span class="talk-vip">讲师</span>
                          <span class="talk-name">讲师</span>
                        </p>
                        <div class="vrt-talk-txt">
                          <p class="vrt-talk-main">您好，请问有什么可以为您解答的？</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="vrt-talk-input-wrap">
                <div class="vrt-input-content">
                  <span class="vrt-input-icon gifticon" @click.stop="openGifticon"></span>
                  <div class="vrt-talk-input-main">
                    <textarea
                        type="text"
                        class="talk-input js-talk-input"
                        maxlength="200"
                        placeholder="我也来参与一下互动..."
                        id="chatipt"
                    ></textarea>
                    <span
                        class="vrt-input-icon faceicon"
                        @click.stop="openFaceicon"
                        id="btn-face"
                    ></span>
                  </div>
                  <span class="vrt-action-img">
                    <input
                        type="file"
                        id="action-img-btn"
                        accept="image/jpeg,image/jpg,image/png,image/gif"
                    />
                  </span>
                  <span class="vrt-input-icon imgicon"></span>
                  <span class="talk-send-btn" id="chatlistbtn" for="all">发送</span>
                </div>
                <div class="vrt-gift-wrap">
                  <div class="vrt-gift-main">
                    <ul>
                      <li
                          data-name="鲜花"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">鲜花</div>
                      </li>
                      <li
                          data-name="咖啡"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">咖啡</div>
                      </li>
                      <li
                          data-name="点赞"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">点赞</div>
                      </li>
                      <li
                          data-name="掌声"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">掌声</div>
                      </li>
                      <li
                          data-name="666"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">666</div>
                      </li>
                      <li
                          data-name="小星星"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">小星星</div>
                      </li>
                      <li
                          data-name="钻石"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">钻石</div>
                      </li>
                      <li
                          data-name="跑车"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">跑车</div>
                      </li>
                      <li
                          data-name="火箭"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">火箭</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-fourth" v-show="itemBool == 4 && liveSwitchTab && intro.liveInvitationOpen==1">

              <div class="invitation-list">

                <div class="rank-title">
                  <div class="rank-left">
                    <div class="rank-left-img">
                      <img src="@/assets/img/liveRoom/rank.png" alt="">
                    </div>
                    <span>排行榜</span>
                  </div>
                  <div class="rank-right" @click="handleCopy">
                    <div>
                      <img src="@/assets/img/liveRoom/share.png" alt="">
                    </div>
                    <span>邀请好友</span>
                  </div>
                </div>
                <div class="rank-prize">
<!--                  <span>第1名：便携式暖手宝；</span>-->
<!--                  <span>第2名：定制保温杯；</span>-->
<!--                  <span>第3名：加热鼠标垫；</span>-->
<!--                  <span>第5-10名：三合一收纳数据线；</span>-->
                  <span>邀请榜仅展示排行榜前10名</span>
                </div>
                <div class="rank-content" v-for="(item, index) in inviteList.data" :key="index">
                  <div class="rank-list">
                    <div class="rank-first">
                      <img src="@/assets/img/liveRoom/rank1.png" alt="" v-if="index==0">
                      <img src="@/assets/img/liveRoom/rank2.png" alt="" v-else-if="index==1">
                      <img src="@/assets/img/liveRoom/rank3.png" alt="" v-else-if="index==2">
                      <p v-else>{{index+1}}</p>
                    </div>

                    <div class="rank-second">
                      <img :src="item.userImg || 'https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/not_loaded.jpg?x-oss-process=image/resize,w_200'" alt="">
                    </div>
                    <span v-if="item.nickName">{{item.nickName}}</span>
                    <span v-else-if="item.mobile">{{item.mobile.substring(0,3)}}****{{item.mobile.substring(7)}}</span>
                  </div>
                  <div class="rank-toil">邀请{{item.receiveCount}}人</div>
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-fifth" v-show="itemBool == 2 && liveSwitchTab">
              <div class="vrt-notice-list">
                <p class="vrt-notice-publish"></p>
                <div class="vrt-notice-follow">
                  <div class="vrt-notice-pic">
                    <div class="vrt-notice-img">
                      <img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/7a7581c7f8b77cdb615d3d22d2c8a4e5.jpg" alt="">
                    </div>
                    <div class="vrt-notice-txt">
                      长按二维码<br/>
                      关注BNCC公众号
                    </div>
                  </div>
                  <div class="vrt-notice-pic">
                    <div class="vrt-notice-img">
                      <img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/7b8730f9d78142c5a1b8efe2c048064d.jpg" alt="">
                    </div>
                    <div class="vrt-notice-txt">
                      长按二维码<br/>
                      添加助教微信
                    </div>
                  </div>
<!--                  <div class="vrt-notice-pic">-->
<!--                    <div class="vrt-notice-img">-->
<!--                      <img src="@/assets/img/liveRoom/notice4.png" alt="">-->
<!--                    </div>-->
<!--                    <div class="vrt-notice-txt">-->
<!--                      长按二维码<br/>-->
<!--                      伟业计量交流群-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>

            </div>
            <div class="vrt-main-tab-sixth" v-if="itemBool == 3 && liveSwitchTab">
              <video-product-card
                  :list="plist"
                  :id="intro.id"
                  :applyProductOpen="applyProductOpen"
              ></video-product-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vrt-main-content" v-else>
      <div class="vrt-main-warp">
        <div class="vrt-tab-warp">
          <div class="vrt-main-tab">
            <div class="swiper-container" id="menu-nav-swiper">
              <ul class="swiper-wrapper">
                <li
                    style="width: 20%;"
                    class="swiper-slide"
                    :class="{ current: itemBoolBack == index, 'current-view': index == 3 }"
                    v-for="(item, index) in menuBack"
                    :key="index"
                    @click="selectMenuBack(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

          </div>
          <div class="vrt-main-tab-content">
            <div class="vrt-main-tab-first" v-show="itemBoolBack == 0">
              <template v-if="courseChapterList.length>0">
                <div class="video-info">
                  <template v-if="live.liveStatus == 0">
                    <div
                        class="count_down count_down_time"
                        v-if="intro.liveStartTime"
                        id="dateShow"
                    >
                      <p>直播倒计时</p>
                      <span class="d">07</span>天 <span class="h">22</span>小时
                      <span class="m">50</span>分 <span class="date-s s">56</span>秒
                      <div @click="followClick"  :class="[showCode ? 'sign' : 'unsign']">{{showText}}</div>
                    </div>
                    <div class="count_down">
                      <span>{{ intro.courseName}}</span>
                    </div>
                  </template>
                  <template v-if="live.liveStatus == 1">
                    <span class="count_down"><span>直播进行中</span></span>
                    <div class="count_down">
                      <span>{{ intro.courseName }}</span>
                    </div>
                  </template>
                  <template v-if="live.liveStatus == 2">
                    <div class="count_down count_down_time" id="dateShow">
                      <p>直播倒计时</p>
                      <span class="d"></span>天 <span class="h"></span>小时
                      <span class="m"></span>分 <span class="date-s s"></span>秒
                      <div @click="followClick"  :class="[showCode ? 'sign' : 'unsign']">{{showText}}</div>
                    </div>
                    <div class="count_down">
                      <span>{{ intro.courseName}}</span>
                    </div>
                  </template>
                  <template v-if="live.liveStatus == 3">
                    <span class="count_down live-end"><span>直播已结束</span></span>
                    <div class="count_down">
                      <span>{{ intro.courseName}}</span>
                    </div>
                  </template>
                  <class-intro :intro="intro"></class-intro>
                </div>

                <div class="vrt-first-content">
                  <div class="course-description">
                    <class-sch-card :courseChapterList="courseChapterList" :isLogin="(uid!=null && uid >0)?true:false"></class-sch-card>
                  </div>
                </div>
              </template>
              <div class="empty" v-else>
                <img src="@/assets/img/common/icon36.png" class="focus_img" />
                <div>
                  暂无信息
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-first" v-show="itemBoolBack == 1">
              <ul class="playback" v-if="RecList.length>0">
                <li class="play-video" v-for="(item, index) in RecList" :key="item.id">
                  <router-link :to="{name: 'videoRealtime', query: { id: intro.id, index: index }}">
                    <img src="@/assets/img/bofang.svg" alt />
                    <div class="video-detail">
                      <div class="video-detail-title">{{ item.recordRoomName }}</div>
                      <div class="video-detail-date">{{ dateFormat(item.recordStartTime) }}</div>
                    </div>
                    <class-sch-card :itemBool="itemBoolBack" :courseChapterList="item.courseChapterVOList" :summaryBool="false" :isLogin="(uid!=null && uid >0)?true:false"></class-sch-card>
                  </router-link>
                </li>
              </ul>
              <div class="empty" v-else>
                <img src="@/assets/img/common/icon36.png" class="focus_img" />
                <div>
                  暂无信息
                </div>
              </div>
            </div>
            <div class="vrt-main-tab-first" style="padding-bottom: 1.33333rem;" v-show="itemBoolBack == 2" v-if="live.liveStatus!=1">
              <class-comment :listComment="listComment" v-if="listComment.length>0"></class-comment>
              <div class="empty" v-else>
                <img src="@/assets/img/common/icon36.png" class="focus_img" />
                <div>
                  暂无信息
                </div>
              </div>
              <class-footer :isBack="true" :isPlay="false" v-model="comment" @submit="subComment"></class-footer>
            </div>
            <div class="vrt-main-tab-second" v-show="itemBoolBack == 3">
              <div class="vrt-talk-list">

                <div class="vrt-radio-list">
                  <div class="vrt-radio-icon">
                    <div>
                      <img src="@/assets/img/liveRoom/horn.png" alt="">
                    </div>
                    <span class="vrt-radio-close">×</span>
                  </div>
                  <div class="vrt-radio-content"></div>
                </div>

                <div class="vrt-talk-list-ul vrt-second-list">
                  <ul>
                    <li>
                      <div class="vrt-talk-logo">
                        <img
                            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/picman.jpg?x-oss-process=image/resize,w_200"
                            alt="北纳生物网站地图，北京北方北纳生物技术研究院，课堂"
                        />
                      </div>
                      <div class="vrt-talk-box">
                        <p class="vrt-talk-info">
                          <span class="talk-vip">讲师</span>
                          <span class="talk-name">讲师</span>
                        </p>
                        <div class="vrt-talk-txt">
                          <p class="vrt-talk-main">您好，请问有什么可以为您解答的？</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="vrt-talk-input-wrap">
                <div class="vrt-input-content">
                  <span class="vrt-input-icon gifticon" @click.stop="openGifticon"></span>
                  <div class="vrt-talk-input-main">
                    <textarea
                        type="text"
                        class="talk-input js-talk-input"
                        maxlength="200"
                        placeholder="我也来参与一下互动..."
                        id="chatipt"
                    ></textarea>
                    <span
                        class="vrt-input-icon faceicon"
                        @click.stop="openFaceicon"
                        id="btn-face"
                    ></span>
                  </div>
                  <span class="vrt-action-img">
                    <input
                        type="file"
                        id="action-img-btn"
                        accept="image/jpeg,image/jpg,image/png,image/gif"
                    />
                  </span>
                  <span class="vrt-input-icon imgicon"></span>
                  <span class="talk-send-btn" id="chatlistbtn" for="all">发送</span>
                </div>
                <div class="vrt-gift-wrap">
                  <div class="vrt-gift-main">
                    <ul>
                      <li
                          data-name="鲜花"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/01-flower.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">鲜花</div>
                      </li>
                      <li
                          data-name="咖啡"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/02-coffee.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">咖啡</div>
                      </li>
                      <li
                          data-name="点赞"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/03-good.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">点赞</div>
                      </li>
                      <li
                          data-name="掌声"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/04-applaud.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">掌声</div>
                      </li>
                      <li
                          data-name="666"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/05-666.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">666</div>
                      </li>
                      <li
                          data-name="小星星"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/06-star.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">小星星</div>
                      </li>
                      <li
                          data-name="钻石"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/07-diamond.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">钻石</div>
                      </li>
                      <li
                          data-name="跑车"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/08-car.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">跑车</div>
                      </li>
                      <li
                          data-name="火箭"
                          data-pic="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                      >
                        <div class="course-gift-img">
                          <img
                              src="//livestatic.videocc.net/uploaded/images/webapp/channel/donate/09-rocket.png"
                              alt=""
                          />
                        </div>
                        <div class="course-gift-name">火箭</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <input type="hidden" v-model="detail.nickname" id="username" />
  <input type="hidden" v-model="uid" id="uid" />
  <template v-if="live.liveStatus==1">
    <input type="hidden" v-model="detail.roomId" id="roomId" />
    <input type="hidden" v-model="detail.viewertoken" id="viewertoken" />
  </template>
  <template v-else>
    <input type="hidden" v-model="detail.liveId" id="liveId" />
    <input type="hidden" v-model="detail.recordRoomId" id="recordRoomId" />
    <input type="hidden" v-model="detail.recordId" id="recordId" />
    <input type="hidden" v-model="live.liveStatus" id="liveStatusId" />
    <input type="hidden" v-model="detail.viewertoken" id="viewertoken" />
  </template>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick, onUnmounted, reactive, watch, inject } from "vue";
import { useRoute } from "vue-router";

import dateFormat, { timeFormat } from "@/utils/dateFormat";
import ClassIntro from "@/components/common/ClassIntro.vue";
import ClassSchCard from "@/components/common/ClassSchCard.vue";
import ClassComment from "@/components/common/ClassComment.vue";
import VideoProductCard from "@/components/common/VideoProductCard.vue";
import api from "@/api/axios";
import axios from "@/api/axios";
import $ from "jquery";
import asyncLoadJs, { removeJs } from "@/hooks/useAsyncLoadJs";
import leftTime, { clearTime } from "@/hooks/useLeftTime";
import wx from "weixin-sdk-js";
import Swiper from "swiper";
import useClipboard from 'vue-clipboard3'
import Toast from "@/components/UI/Toast";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";
import Clipboard from 'clipboard'
import ClassFooter from "@/components/common/ClassFooter.vue";


export default defineComponent({
  name: "videoRealtime",
  components: {
    ClassIntro,
    ClassSchCard,
    VideoProductCard,
    ClassComment,
    ClassFooter
  },
  setup() {
    const shareTitle = ref('')
    const videoImgUrl = ref('')
    const route = useRoute();
    const id = ref(route.query.id);
    const recIndex = route.query.recIndex;
    // console.log("rec",recIndex);
    const vip = route.query.vip;
    const intro = ref({});
    const indexType = ref({});
    const detail = ref({});
    const courseChapterList = ref<object>([]);
    const live = ref({});

    const showCode=ref(true);

    const showText=ref('预约提醒');

    // const plist = reactive<object[]>([]);
    const plist = reactive({data: []});
    const applyProductOpen = ref(false);
    const inviteList = reactive({data: []});
    let timer: any = null;
    let inviteTimer: any = null
    let followTimer: any = null
    const liveRedPacket = ref(false)
    const router = useRouter();
    const liveProductSwitch = ref()
    const liveSwitchTab = ref(true)
    const menu = ref()
    const menuBack = ref()
    const urlData = ref()
    const visible = ref(false)
    const visiblePic = ref(false)
    const uid = ref()
    const codeImg = ref()
    const RecList = ref<object>([]);
    const listComment = ref<object>([]);
    const comment = ref("");
    const errorMsgStr =ref();
    const user = inject("userInfo") as object;
    const isFollow =  ref(false);
    function getRenderData(id: any,index: any) {
      api
          .get("/M/Discover/VideoRealtime/" + id, {
            params: {
              recIndex: index,
              vip: vip,
            },
          })
          .then((res) => {
            if (res.data.success == false) {
              if(res.data.msg =='请先登录再执行此操作'){
                Toast({
                  type: "error",
                  title: res.data.msg,
                  onClose: function () {
                    router.push({
                      name: "Login",
                      query: {
                        redirect: router.currentRoute.value.fullPath,
                      },
                    });
                  }
                });
              }else{
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            }else{
              const data = res.data.obj;
              shareTitle.value = data.course.courseName;//安卓获取的分享title
              document.title =data.course.courseName + "-北纳生物";//苹果获取的分享title
              route.meta.content.description = data.course.courseDescription;

              errorMsgStr.value = data.errorMsg;
              if(data.errorMsg=="NO_RECORD"){
                Toast({
                  type: "error",
                  title: "暂无回放",
                });
              }
              // liveProductSwitch.value = data.liveProductSwitch
              liveProductSwitch.value = data.course.liveType
              if (data.course.liveType == 0) {
                liveSwitchTab.value = false
                if (data.live.liveStatus==1) {
                  menuBack.value = ["课程概述", "回放目录(0)", "聊天"];
                } else {
                  menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
                  menuBack.value[2] = "评论(" + data.listComment.length + ")";
                }
              } else {
                liveSwitchTab.value = true
                if(data.course.liveInvitationOpen == 1){
                  menu.value = ["介绍", "聊天", "公告", "抢购", "邀请榜"];
                }else{
                  menu.value = ["介绍", "聊天", "公告", "抢购"];
                }
                menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
              }
              if(data.course.liveAward){
                data.course.liveAward = data.course.liveAward.replace(/\n/g,"<br/>").replace(/\s/g, "&nbsp;");
              }
              intro.value = data.course;
              detail.value = data;
              courseChapterList.value = data.courseChapterList;
              live.value = data.live || '';
              uid.value = data.currentUser && data.currentUser.id;
              RecList.value = data.recList;
              menuBack.value[1] = "回放目录(" + data.recList.length + ")";
              listComment.value = data.listComment;

              nextTick(() => {
                asyncLoadJs("js/jquery-3.1.1.min.js", "videoRealtime").then(() => {
                  if (data.live.liveStatus==3) {
                    asyncLoadJs("js/playbackSDK.js", "videoRealtime").then(() => {
                      asyncLoadJs("js/livePlayBack.js", "videoRealtime");
                    });
                  } else {
                    asyncLoadJs("js/base.js", "videoRealtime").then(() => {
                      asyncLoadJs("js/liveSDK.JS", "videoRealtime").then(() => {
                        asyncLoadJs("js/videoRealtime.js", "videoRealtime");
                      });
                    });
                  }
                });
                leftTime(data.course.liveStartTime, function (d: any) {
                  if (d.status) {
                    const $dateShow1 = $("#dateShow");
                    $dateShow1.find(".d").html(d.d);
                    $dateShow1.find(".h").html(d.h);
                    $dateShow1.find(".m").html(d.m);
                    $dateShow1.find(".s").html(d.s);
                  }
                })
              });

            }

            followTimer = setTimeout(() => {
              if (!res.data.obj.currentUser || res.data.obj.currentUser == 'null') {
                Modal({
                  title: "温馨提示",
                  optionControl:1,
                  closeText:"关闭",
                  content: `
                <div>添加助教微信，领取直播福利</div>
                <div style="height: 120px;">
                  <div style="text-align: center;width: 98%;">
                    <img width="140px" src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/7b8730f9d78142c5a1b8efe2c048064d.jpg" alt="">
                  </div>
                </div>
                `,
                  dangerouslyUseHTMLString: true,
                });
              }
            }, 10000)
          })
          .catch((err) => {
            // console.log(err);
          });
    }
    getRenderData(id.value,recIndex)
    function toLogin() {
      router.push({
        path: "/login",
        query: {
          redirect: router.currentRoute.value.fullPath
        }
      });
    }
    function contrast(startDate: string) {
      return new Date().getTime() - new Date(timeFormat(startDate)).getTime() < 0
          ? true
          : false;
    }
    function followClick(id: any) {

      if(this.showCode==true) {
        this.showCode = false;
        this.showText = '已预约';
        api.get("/M/Discover/followLive", {
          params: {
            id: (intro.value as any).id
          },
        }).then((res) => {
          const data = res.data
          if(data.msg == '用户未登录') {
            Toast({
              title: '请先登录',
              type: "error",
            });
            router.push({
              path: "/login"
            });
          }
          if (res.data.success){
            Toast({
              type: "success",
              title: "预约成功！",
            });
          }
        })
      } else{
        this.showCode=true;
        this.showText='预约提醒';


        api.get("/M/Discover/cancelfollowLive", {
          params: {
            id: (intro.value as any).id
          },
        }).then((res) => {
          const data = res.data
          if(data.msg == '用户未登录') {
            Toast({
              title: '请先登录',
              type: "error",
            });
            router.push({
              path: "/login"
            });
          }
          if (res.data.success){
            Toast({
              type: "success",
              title: "已取消预约",
            });
          }
        })
      }
      // this.showCode = !this.showCode;
    }
    //  切换列表菜单
    // const menu = ref(["直播介绍", "互动聊天", "提问", "邀请栏", "公告", "商品栏"]);
    const itemBool = ref(0);
    const itemBoolBack = ref(0)
    function selectMenu(item: string) {
      if (item == "介绍") {
        itemBool.value = 0;
      }
      if (item.indexOf("聊天") != -1) {
        itemBool.value = 1;
      }
      // if (item == "提问") {
      //   itemBool.value = 2;
      // }
      if (item == "邀请榜") {
        itemBool.value = 4;

        api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
          id: (intro.value as any).id
        }))
            .then((res) => {
              inviteList.data = res.data.data
            })
            .catch((err) => {
              console.log(err);
            });
        codeImg.value = "/M/Server/createPoster?id=" + (intro.value as any).id+"&uid="+uid.value;
      }
      if (item == "公告") {
        itemBool.value = 2;
        $('.five-dot i').hide()
      }
      if (item == "抢购") {
        itemBool.value = 3;

        api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
          id: (intro.value as any).id
        }))
            .then((res) => {
              // res.data.obj.forEach((item: any) => {
              //   plist.push(item);
              // });
              if(res.data.type == '1'){
                applyProductOpen.value = true
              }else{
                applyProductOpen.value = false
              }
              console.log("applyProductOpen1",applyProductOpen.value)
              plist.data = res.data.obj
            })
            .catch((err) => {
              // console.log(err);
            });

      }

      // 广播通知位置修改
      if (itemBool.value == 1) {
        if ($(".vrt-radio-list").attr('style') == 'display: block;') {
          nextTick(() => {
            const vrtRadioHeight = $('.vrt-radio-list').outerHeight()
            $('.vrt-talk-list-ul').css('padding-top', ((vrtRadioHeight as any)+10)+'px')
          })
        }
      } else {
        $('.vrt-talk-list-ul').removeAttr('style')
      }
    }
    function selectMenuBack(item: string) {
      if (item == "课程概述") {
        itemBoolBack.value = 0;
      }
      if (item.indexOf("回放目录") != -1) {
        itemBoolBack.value = 1;
      }
      if (item.indexOf("评论") != -1) {
        itemBoolBack.value = 2;
      }
      if (item.indexOf("聊天") != -1) {
        itemBoolBack.value = 3;
      }
    }

    onMounted(() => {
      //获取进入直播间订阅状态
      api.get("/M/Discover/findFollow",{
        params: {
          id: id.value
        },
      }).then((res) => {
        if (res.data.success){
          const data = res.data;
          isFollow.value=data.obj.isfollow;
          // console.log("isfollow"+isFollow.value);
          if(isFollow.value){
            showText.value='已订阅'
            showCode.value = false
          }
        }
      });
      new Swiper("#menu-nav-swiper", {
        initialSlide: 0,
        slidesPerView: 5,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        on: {
          observerUpdate: function () {
            this.slideTo(0);
          },

        },
        // onSlideChangeEnd: function(){
        //   }
      });


    })


    onUnmounted(() => {
      removeJs("jquery-3.1.1.min.js")
      removeJs("base.js")
      removeJs("liveSDK.JS")
      removeJs("videoRealtime.js")
      removeJs("playbackSDK.js")
      removeJs("livePlayBack.js");

      removeJs("socket.io.js")
      removeJs("swfobject.js")
      removeJs("json3.min.js")
      removeJs("report.js")
      removeJs("dpc.js")
      //销毁当前页面的倒计时器
      clearTime();
    })


    const { toClipboard } = useClipboard()
    const copy = async (Msg: any) => {
      try {
        //复制
        await toClipboard(Msg)
        // console.log(Msg)
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
      } catch (e) {
        //复制失败
        // console.error(e)
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
      }
    }
    function clickBtn(){

      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {
        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))
            .then((res) => {
              if (!res.data.success) {
                Toast({
                  type: "error",
                  title: res.data.msg
                });
              }
              copy(res.data.obj)
            })
            .catch((err) => {
              // console.log(err);
            });
      }
    }

    function handleCopy() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {

        codeImg.value = "/M/Server/createPoster?id=" + (intro.value as any).id+"&uid="+uid.value;
        visiblePic.value = true

        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))
            // api.post("/M/Server/createPoster", qs.stringify({
            //   id: (intro.value as any).id,
            //   uid: uid.value
            // }))
            .then((res) => {
              // if (!res.data.success) {
              //   Toast({
              //     type: "error",
              //     title: res.data.msg
              //   });
              // } else {
              //   visible.value = true
              //   urlData.value = res.data.obj
              // }
            })
            .catch((err) => {
              // console.log(err);
            });
      }
    }

    function confirm() {
      const clipboard = new Clipboard('.confirm-copy');
      clipboard.on('success', (e) => {
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
        // 释放内存
        visible.value = false
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
        // 释放内存
        clipboard.destroy()
      })
    }
    function close() {
      visible.value = false
    }
    function closePic() {
      visiblePic.value = false
    }



    watch(
        () => {
          return itemBool.value
        },
        (newValue) => {
          //如果当前直播状态是已结束就，不定时刷新抢购产品
          if (newValue == 3 && (live.value as any).liveStatus != 3) {
            $('.five-dot i').hide()
            timer = setInterval(() => {
              api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
                id: (intro.value as any).id
              }))
                  .then((res) => {
                    // console.log("444444",res)
                    // console.log("555555555555555",res.data);
                    if(res.data.type == '1'){
                      applyProductOpen.value = true;
                    }else{
                      applyProductOpen.value = false;
                    }
                    // console.log("applyProductOpen",applyProductOpen)
                    // console.log("66666666");
                    for(let i=0;i<=plist.data.length;i++) {
                      // console.log("777777777777");
                      if (res.data.obj[i].type != (plist.data[i] as any).type) {
                        (plist.data[i] as any).type = res.data.obj[i].type
                      }
                      if (res.data.obj[i].stock != (plist.data[i] as any).showStock) {
                        (plist.data[i] as any).showStock = res.data.obj[i].showStock
                      }
                    }

                  })
                  .catch((err) => {
                    // console.log(err);
                  });
            }, 10000);
          } else {
            clearInterval(timer)
            plist.data = []
          }

          if (newValue == 4) {
            inviteTimer = setInterval(() => {
              api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
                id: (intro.value as any).id
              }))
                  .then((res) => {
                    inviteList.data = res.data.data
                  })
                  .catch((err) => {
                    // console.log(err);
                  });
            }, 2000);
          } else {
            clearInterval(inviteTimer)
            // inviteList.data = []
          }
        }
    )

    // 定时轮训是否显示红包雨
    const redRain = setInterval(() => {
      api.post("/M/CourseServer/GetLiveOpenList", qs.stringify({
        id: (intro.value as any).id
      }))
          .then((res) => {
            if (res.data.obj.liveRedPacket) {
              liveRedPacket.value = true
            } else {
              liveRedPacket.value = false
            }
          })
          .catch((err) => {
            // console.log(err);
          });
    }, 10000);

    // 直播跳转红包
    function liveJumpRed() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后可参与，前往登录",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onClose: () => {
            // console.log('1')
          },
        });
        return false;
      }

      const fData = new FormData();
      fData.append("returnurl", 'http://m.bncc.com/videored?courseId='+(intro.value as any).id);
      api
          .post("/M/Event/redPacketWeChatUrl", fData)
          .then((res) => {
            if (res.data.success) {
              window.location.href = res.data.returnUrl;
            } else {
              Toast({
                title: res.data.msg,
                type: "error",
              });
            }
          })
          .catch(() => {
            // console.log('false false')
          });
    }

    watch(
        [
          () => {
            return shareTitle.value;
          }
        ],
        (to, from) => {
          if (from && from[0] == 'videoRealtime') {
            clearInterval(inviteTimer)
            clearInterval(timer)
            clearInterval(redRain)
            clearTimeout(followTimer)
          }
          const isweixin = ref(false);
          const ua = navigator.userAgent.toLowerCase();
          const reg = /MicroMessenger/i;
          isweixin.value = reg.test(ua);
          if (isweixin.value) {
            nextTick(() => {
              const link = window.location.href;
              const formData = new FormData();
              formData.append("url", link);
              axios.post("/M/Server/getweixin", formData).then((res: any) => {
                const data = res.data;
                wx.config({
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: data.appid, // 必填，公众号的唯一标识
                  timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                  nonceStr: data.nonceStr, // 必填，生成签名的随机串
                  signature: data.str, // 必填，签名
                  jsApiList: [
                    "checkJsApi",
                    "updateAppMessageShareData",
                    "updateTimelineShareData",
                  ], // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                  //需在用户可能点击分享按钮前就先调用

                  wx.updateAppMessageShareData({
                    title: to+'-北纳生物', // 分享标题
                    desc: route.meta.content.description, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                    success: function () {
                      // 设置成功
                    },
                  });
                  wx.updateTimelineShareData({
                    title: to+'-北纳生物', // 分享标题
                    desc: route.meta.content.description, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                    success: function () {
                      // 设置成功
                    },
                  });
                });
              });
            });
          }
        }
    );
    watch(
        [
          () => {
            return route.name;
          }
        ],
        (to, from) => {
          if (from && from[0] == 'videoRealtime') {
            clearInterval(inviteTimer)
            clearInterval(timer)
            clearInterval(redRain)
            clearTimeout(followTimer)
          }
        }
    );
    watch(
        () => {
          return liveProductSwitch.value
        },
        (newValue) => {
          if (newValue == 0) {
            //newValue == 0 表示研讨会
            clearInterval(inviteTimer)
            clearInterval(timer)
            clearInterval(redRain)
            // clearTimeout(followTimer)
          }
        }
    )
    watch(
        () => {
          return menu.value
        },
        () => {
          new Swiper("#menu-nav-swiper", {
            initialSlide: 0,
            slidesPerView: 5,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },
            },
          });
        }
    )
    watch(
        () => {
          return menuBack.value
        },
        () => {
          new Swiper("#menu-nav-swiper", {
            initialSlide: 0,
            slidesPerView: 5,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },
            },
          });
        }
    )
    watch(
        [
          () => {
            return route.query;
          },
          () => {
            return route.params;
          },
        ],
        (to, from) => {
          // 避免登录时触发页面刷新
          const index=String(to[0].recIndex)
          if (to[0].id || index) {
            id.value = to[0].id;
            getRenderData(to[0].id as string,index as any);

          }
        }
    );
    function subComment() {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        id,
        content: comment.value,
        type: 0
      });
      axios
          .post("/M/Server/DoCourseComment", data)
          .then((res) => {
            if (res.data.success) {
              Toast({
                type: "success",
                title: res.data.msg,
              });
              (listComment.value as object[]).unshift({
                ...res.data.obj,
                isAlreadyZan: 0,
                user: {
                  ...user,
                  userImg: (user as any).userImg,
                },
              });
              menuBack.value[2] = "评论(" + (listComment.value as object[]).length + ")";
              comment.value = "";
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
              router.push({name: "Login"})
            }
          })
          .catch((err) => {

            const message = err.message ? err.message : "未知错误，请联系管理员";
            Toast({
              type: "error",
              title: message,
            });
          });
    }
    if (location.href.indexOf("reloaded") == -1) {
      location.href = location.href + "#reloaded";
      // alert(location.href)
      // location.href =location.href.replace('reloaded',"")
      setTimeout(function () {
        location.reload();
      },1000)

    }
    return {
      contrast,
      toLogin,
      intro,
      uid,
      courseChapterList,
      live,
      menu,
      menuBack,
      selectMenu,
      selectMenuBack,
      itemBool,
      itemBoolBack,
      detail,
      plist,
      applyProductOpen,
      clickBtn,
      liveJumpRed,
      liveRedPacket,
      inviteList,
      liveSwitchTab,
      handleCopy,
      urlData,
      confirm,
      close,
      visible,
      visiblePic,
      codeImg,
      closePic,
      RecList,
      dateFormat,
      listComment,
      comment,
      errorMsgStr,
      showCode,
      followClick,
      showText,
      subComment
      // openGifticon,
      // openFaceicon
    };
  },
});
</script>

<style src=".././assets/styles/videoRealtime.css"></style>

<style lang="scss" scoped>
.author {
  padding-left: 62px;
}
#dateShow span {
  color: #df0024;
}

// 邀请栏
.invitation-list {
  padding: 15px 15px 10px 15px;
}
.rank-title {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.rank-prize {
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.rank-prize span {
  width: 100%;
  display: block;
  line-height: 30px;
}
//.rank-prize span:nth-child(1) {
//  width: 42%;
//}
//.rank-prize span:nth-child(3) {
//  width: 42%;
//}
//.rank-prize span:nth-child(2) {
//  width: 58%;
//}
//.rank-prize span:nth-child(4) {
//  width: 58%;
//}
//.rank-prize span:last-child {
//  width: 100%;
//}
.rank-left {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
  font-weight: bold;
}
.rank-left-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: -2px;
  img {
    width: 100%;
  }
}
.rank-right {
  display: flex;
  font-size: 12px;
  color: #df0024;
}
.rank-right>div {
  width: 14px;
  height: 14px;
  img {
    width: 100%;
    margin-right: 5px;
  }
}
.rank-right > span {
  margin-left: 5px;
}


.rank-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.rank-list {
  display: flex;
  align-items: center;
}
.rank-first {
  width: 20px;
  height: 20px;
  line-height: 23px;
  text-align: center;
  margin-right: 10px;
  img {
    position: relative;
    // top: 3px;
    width: 100%;
  }
}
.rank-second {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  img {
    border-radius: 50%;
    width: 100%;
  }
}

// 邀请栏
.vrt-main-tab-fourth {
  height: 100%;
  overflow: auto;
}

/* 公告 */
.vrt-main-tab-fifth {
  background: url("../assets/img/liveRoom/notice.png") no-repeat;
  background-position: 100% 130%;
  background-size: contain;
}

// 公告
.vrt-notice-list {
  padding: 15px 15px 10px 15px;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}
.vrt-notice-list p {
  line-height: 25px;
  text-indent: 8px;
  color: #393939;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.vrt-notice-follow {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  padding-bottom: 70px;
}
.vrt-notice-pic {
  background: #00B278;
}
.vrt-notice-img {
  // width: 160px;
  // height: 160px;
  width: 112px;
  height: 112px;
  padding: 5px;
  img {
    width: 100%;
  }
}
.vrt-notice-txt {
  font-size: 14px;
  color: #fff;
  text-align: center;
  height: 45px;
  // height: 25px;
  // line-height: 22px;
}

// 商品栏
.vrt-main-tab-sixth {
  height: 100%;
  overflow: auto;
}


// 广播、通知
.vrt-radio-list {
  position: absolute;
  top: 0;
  background: #FFF7E9;
  width: 100%;
  padding: 10px;
  padding-top: 0;
  font-size: 14px;
  z-index: 9;
  display: none;
}
.vrt-radio-content {
  color: #F99136;
}
.vrt-radio-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vrt-radio-icon > div {
  width: 20px;
  height: 20px;
  img {
    width: 100%;
  }
}
.vrt-radio-icon > span {
  font-size: 25px;
  font-weight: bold;
  position: relative;
  top: 1px;
}


// 抽奖
.luck-draw {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  .toast {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .red-package-content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    img{
      width: 100%;
    }
    .close{
      width: 32px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(400%);
      z-index: 100;
    }
  }
}

.lottery {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999999;
  left: 10%;
  // display: none;
}

.lotterydiv {
  background: #fffcdc url('../assets/img/liveRoom/lottery04.gif') center -46px no-repeat;
  // background-size: 140%;
  background-size: 150%;
  background: #fffcdc url('../assets/img/liveRoom/lottery05.gif') center -46px no-repeat \9;
  border: 1px solid #ff512c;
  z-index: 999;
  box-shadow: inset 0px -4px 0px 0px #ff512c;
  // width: 270px;
  width: 200px;
  // height: 190px;
  height: 150px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -95px 0 0 -135px;
}

.lotteryh3 {
  background: url('../assets/img/liveRoom/lottery01.png') no-repeat;
  text-align: center;
  line-height: 50px;
  color: #FFF;
  font-size: 18px;
  // width: 325px;
  width: 255px;
  height: 50px;
  position: absolute;
  background-size: 100%;
  background-image: none \9;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../assets/img/liveRoom/lottery01.png', sizingMethod='scale') \9;
  top: -24px;
  left: -28px;
}

.lotteryclose {
  background: url('../assets/img/liveRoom/lottery02.png') no-repeat;
  width: 30px;
  height: 30px;
  background-size: 100%;
  background-image: none \9;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='../assets/img/liveRoom/lottery02.png', sizingMethod='scale') \9;
  position: absolute;
  top: -13px;
  right: 6px;
  cursor: pointer;
}

.lotterybox {
  position: absolute;
  top: 39px;
  // top: 62px;
  width: 100%;
  // height: 110px;
  height: 100px;
  background: #fffcdc;
  display: none;
}

.lotterytext2 {
  font-size: 14px;
  color: #666666;
  line-height: 28px;
  text-align: center;
}

.lotteryname {
  font-size: 21px;
  color: #ff512c;
  line-height: 42px;
  text-align: center;
  // width: 200px;
  margin: 0 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// 公告、广播变更显示通知
.five-dot {
  position: relative;
}


// 复制链接
.toast-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  color: #333;
  padding: 10px 20px;
  min-height: 50px;
  max-width: 340px;
  width: 80%;
  font-size: 14px;
  box-sizing: border-box;
  .modal-header {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
  }
  .modal-content {
    padding: 15px 0;
    line-height: 24px;
    text-align: center;
  }
  .modal-footer {
    display: flex;
    line-height: 40px;
    div {
      flex: 1;
      text-align: center;

      background-color: #d64b4b;
      border-radius: 5px;
      color: #fff;
    }
    .confirm {
      color: #df0024;
    }
  }
  img {
    width: 30px;
    height: 30px;

    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
  }
}
.modal-input input {
  border: none;
  line-height: 44px;
  width: 100%;
  display: block;
  margin: 5px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding-left: 5px;
  box-sizing: border-box;
}


.playback-author-warp {
  padding: 20px 10px 50px 10px;
}
.playback li {
  position: relative;

  border-bottom: 1px solid #eeeeee;
  padding: 0.467rem 0.267rem 0.533rem 0.267rem;
  padding-left: 1.6rem;
  box-sizing: border-box;
  overflow: hidden;

}
.playback li > a > img {
  width: 0.933rem;
  height: 0.933rem;
  position: absolute;
  left: 0.267rem;
  top: 0.56rem;
}
.video-detail-title {
  color: #444444;
  font-size: 0.426rem;
  /*width: 6rem;*/
}
.video-detail-date {
  width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999999;
  font-size: 0.373rem;
  margin-top: 0.267rem;
}

.empty {
  padding-top: 100px;
  text-align: center;
  padding-bottom: 50px;

  color: #9f9f9f;
  font-size: 13px;
}
.empty img {
  width: 132px;
  height: 104px;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.empty a {
  font-size: 13px;
  color: #df0024;
}

</style>
<style>
.five-dot i {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #df0024;
  display: inline-block;
  left: 58px;
  top: 3px;
  border-radius: 50%;
  display: none;
}

.sign{
  width: 1.8rem;
  height: 0.8rem;
  background-color: #d64b4b;
  border-radius: 0.1rem;
  line-height: 0.8rem;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  /*font-size: 0.3rem;*/
  font-size: 14px;
  display: inline-block;
  margin-left: 2.6rem;
}
.unsign{
  width: 1.8rem;
  height: 0.8rem;
  background-color: #ddd;
  border-radius: 0.1rem;
  line-height: 0.8rem;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  display: inline-block;
  margin-left: 2.6rem;
}
/* 抽奖2.0 */
  #lottery2{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: auto;
  position: fixed!important;
  top: 0;
  bottom: 0;
  /*display: none;*/
}
#lottery2 .lottery_wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;
}
#lottery2 .lottery_wrap .lottery_content{
  width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}

#lottery2 .height-scroll{
  height: 590px;
  width: 375px;
  overflow-y: auto;
  position: relative;
}


#lottery2 .lottery_wrap .lottery_img{
  width: 350px;
  /*height: 350px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}


#lottery2 .lottery_wrap .lottery_img img{
  width: 250px;
}

#lottery2 .lottery_wrap .lottery_title_img{
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#lottery2 .lottery_wrap .lottery_title_img img{
  width: 250px;
}
#lottery2 .lottery_wrap .lottery_close{
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  top: 0;
  right: 45px;
  z-index: 20;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
#lottery2 .lottery_wrap .lottery_close img{
  width:25px;
}
.luckey-draw .luckey-drawdiv{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.luckey-draw .luckey-drawing {
  background-size: 140%;
  z-index: 999;
  /* width: 270px; */
  /* height: 190px; */
  border-radius: 0.10667rem;
  position: absolute;
  left: 50%;
  top: -30%;
  transform: translate(-50%, 50%);
  /* margin: 0 auto; */
  display: none;
}
/*
.luckey-draw .luckey-drawing {
    background: #fffcdc url(//static.csslcloud.net/view_live/v1.9.0/assets/img/mobile/lottery/lottery-gift_1ecd1ce5.gif) center -46px no-repeat;
    background-size: 140%;
    background: #fffcdc url(//static.csslcloud.net/view_live/v1.9.0/assets/img/mobile/lottery/lottery-gift_1ecd1ce5.gif) center -46px no-repeat\9;
    border: 1px solid #ff512c;
    z-index: 999;
    box-shadow: inset 0px -4px 0px 0px #ff512c;
    width: 270px;
    height: 190px;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -95px 0 0 -135px;
    display: none;
}
*/
.luckey-draw .luckey-drawing-h3 {
  background: url(https://static.csslcloud.net/img/lottery01.png) no-repeat;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  width: 325px;
  height: 50px;
  position: absolute;
  background-size: 100%;
  background-image: none\9;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://static.csslcloud.net/img/lottery01.png', sizingMethod='scale')\9;
  top: -24px;
  left: -28px;
}
.luckey-draw .luckey-drawing-close {
  background: url(https://static.csslcloud.net/img/lottery02.png) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 100%;
  background-image: none\9;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://static.csslcloud.net/img/lottery02.png', sizingMethod='scale')\9;
  position: absolute;
  top: -13px;
  right: 6px;
  cursor: pointer;
}
.luckey-draw .luckey-draw-result{
  width: 300px;
  z-index: 1;
  /*border: 1px solid #ff512c;*/
  /*box-shadow: inset 0px -4px 0px 0px #ff512c;*/
  border-radius: 4px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFCDC;
  display: none;
}
.luckey-draw .tips-box {
  display: none;
  padding-bottom: 50px;
  width: 300px;
  background: #fff;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.luckey-draw .tips-box .tips-title {
  width: 300px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}
.luckey-draw .tips-box .tips-content {
  width: 300px;
  text-align: center;
}
.luckey-draw .tips-box .tips-content .tips-desc{
  margin: 20px;
  font-size: 14px;
}
.luckey-draw .tips-box .tips-btn {
  position: absolute;
  right: 0px;
  margin: auto;
  left: 0px;
  text-align: center;
  outline: none;
}
.luckey-draw .tips-box .tips-btn .tips-submit {
  background: #57b8f4;
  color: #fff;
  border: none;
  font-size: 12px;
  width: 55px;
  height: 23px;
  margin: 14px 8px 0 0;
  border-radius: 2px;
}
.luckey-draw .tips-box .tips-btn .tips-cancel {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 55px;
  height: 23px;
  margin: 14px 8px 0 0;
  color: #666;
  font-size: 12px;
}
.luckey-draw button{
  padding: 0;
}

.luckey-draw-result .luckey-draw-success{
  display: none;
  width: 120px;
  height: 120px;
  background: url(https://static.csslcloud.net/images/lottery-v2/success.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.luckey-draw-result .luckey-draw-error{
  display: none;
  width: 120px;
  height: 120px;
  background: url(https://static.csslcloud.net/images/lottery-v2/error.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.luckey-draw-result .luckey-draw-success p{
  font-size: 13px;
  color: #FFFFFF;
  width: 100%;
  height: 13px;
  margin: 78px auto 0;
  text-align: center;
}
.luckey-draw-result .luckey-draw-error p{
  font-size: 13px;
  color: #FFFFFF;
  width: 95px;
  height: 13px;
  margin: 78px auto 0;
  text-align: center;
}
.luckey-draw-result .luckey-draw-result-h3{
  width: 350px;
  height: 50px;
  background: url(https://static.csslcloud.net/img/lottery01.png) no-repeat;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  position: absolute;
  background-size: 100%;
  background-image: none\9;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://static.csslcloud.net/img/lottery01.png', sizingMethod='scale')\9;
  top: -24px;
  left: -28px;
}
.luckey-draw-result .luckey-draw-result-close {
  background: url(https://static.csslcloud.net/img/lottery02.png) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 100%;
  background-image: none\9;
  /* filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='https://static.csslcloud.net/img/lottery02.png', sizingMethod='scale')\9; */
  position: absolute;
  top: -13px;
  right: 6px;
  cursor: pointer;
}
.luckey-draw-result .luckey-draw-content{
  width: 100%;
  max-height: 449px;
  overflow-y: scroll;
}
.luckey-draw-result .luckey-draw-content::-webkit-scrollbar{
  width:4px;
  background-color: rgba(0, 0, 0, 0);
}
.luckey-draw-result .luckey-draw-content::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: rgba(153, 153, 153, 1);
}
.luckey-draw-result .win-prize-tips{
  margin: 46px 30px 20px 30px;
  color: #333333;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
}
.luckey-draw-result .win-prize-tips span{
  color: #FF412E;
}
.luckey-draw-result .win-prize-num {
  font-family: Arial;
  background: url(https://static.csslcloud.net/img/lottery03.png) no-repeat;
  width: 207px;
  height: 92px;
  background-size: contain;
  color: #ff412e;
  text-indent: 26px;
  line-height: 92px;
  margin: 0 auto 20px;
  font-size: 30px;
}
.luckey-draw-result .cash-prize-tips{
  color: #333333;
  font-size: 12px;
  margin: 7px 10px 20px 10px;
  text-align: center;
}
.luckey-draw-info li{
  width: 298px;
  margin-left: 16px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.luckey-draw-info label{
  width: 48px;
  display: inline-block;
  margin-right: 5px;
  text-align: right;
  font-size: 12px;
  color: #333333;
}
.luckey-draw-info input{
  width: 226px;
  height: 30px;
  margin-bottom: 0px;
  border-radius: 4px;
}
.luckey-draw-info .submit-msg-tips{
  height: 12px;
  text-align: center;
  color: #FF412E;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 10px;
}
.luckey-draw-info .luckey-draw-btn{
  width: 140px;
  height: 40px;
  background-color: #FF412E;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin: 0 auto 20px;
  border-radius: 4px;
  cursor: pointer;
}
.luckey-draw-result .win-prize-wrap{
  width: 294px;
  background-color: rgba(153, 153, 153, .2);
  border-radius: 10px;
  margin: 0 auto 20px;
  position: relative;
}
.luckey-draw-result .win-prize-min-height{
  height: 32px;
  overflow: hidden;
}
.luckey-draw-result .winning-list-title{
  width: 294px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #333333;
  font-size: 12px;
}
.luckey-draw-result .winning-list-icon{
  width: 10px;
  height: 6px;
  position: absolute;
  top: 13px;
  right: 107px;
}
.luckey-draw-result .down {
  background: url(https://static.csslcloud.net/images/lottery-v2/down.png) no-repeat center;
  background-size: contain;
}
.luckey-draw-result .up {
  background: url(https://static.csslcloud.net/images/lottery-v2/up.png) no-repeat center;
  background-size: contain;
}
.win-prize-wrap ul{
  display: inline-block;
}
.win-prize-wrap li{
  display: inline-block;
  width: 50px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.win-prize-wrap li div{
  width: 26px;
  height: 26px;
  margin: 0 auto 10px;
}
.win-prize-wrap li div img{
  width: 100%;
}
.win-prize-wrap li p{
  font-size: 12px;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.luckey-draw-result .not-win-prize-box {
  display: none;
}
.luckey-draw-result .not-win-prize{
  width: 48px;
  height: 51px;
  margin: 46px auto 20px;
}
.luckey-draw-result .not-win-prize img{
  width: 100%;
}
.luckey-draw-result .not-win-prize-tips{
  margin: 0px 20px 20px 20px;
  color: #333333;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
}
.luckey-draw-result .not-win-prize-tips span{
  color: #FF412E;
}


</style>





import { defineComponent, ref, onMounted, nextTick, onUnmounted, reactive, watch, inject } from "vue";
import { useRoute } from "vue-router";

import dateFormat, { timeFormat } from "@/utils/dateFormat";
import ClassIntro from "@/components/common/ClassIntro.vue";
import ClassSchCard from "@/components/common/ClassSchCard.vue";
import ClassComment from "@/components/common/ClassComment.vue";
import VideoProductCard from "@/components/common/VideoProductCard.vue";
import api from "@/api/axios";
import axios from "@/api/axios";
import $ from "jquery";
import asyncLoadJs, { removeJs } from "@/hooks/useAsyncLoadJs";
import leftTime, { clearTime } from "@/hooks/useLeftTime";
import wx from "weixin-sdk-js";
import Swiper from "swiper";
import useClipboard from 'vue-clipboard3'
import Toast from "@/components/UI/Toast";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";
import Clipboard from 'clipboard'
import ClassFooter from "@/components/common/ClassFooter.vue";


export default defineComponent({
  name: "videoRealtime",
  components: {
    ClassIntro,
    ClassSchCard,
    VideoProductCard,
    ClassComment,
    ClassFooter
  },
  setup() {
    const shareTitle = ref('')
    const videoImgUrl = ref('')
    const route = useRoute();
    const id = ref(route.query.id);
    const recIndex = route.query.recIndex;
    // console.log("rec",recIndex);
    const vip = route.query.vip;
    const intro = ref({});
    const indexType = ref({});
    const detail = ref({});
    const courseChapterList = ref<object>([]);
    const live = ref({});

    const showCode=ref(true);

    const showText=ref('预约提醒');

    // const plist = reactive<object[]>([]);
    const plist = reactive({data: []});
    const applyProductOpen = ref(false);
    const inviteList = reactive({data: []});
    let timer: any = null;
    let inviteTimer: any = null
    let followTimer: any = null
    const liveRedPacket = ref(false)
    const router = useRouter();
    const liveProductSwitch = ref()
    const liveSwitchTab = ref(true)
    const menu = ref()
    const menuBack = ref()
    const urlData = ref()
    const visible = ref(false)
    const visiblePic = ref(false)
    const uid = ref()
    const codeImg = ref()
    const RecList = ref<object>([]);
    const listComment = ref<object>([]);
    const comment = ref("");
    const errorMsgStr =ref();
    const user = inject("userInfo") as object;
    const isFollow =  ref(false);
    function getRenderData(id: any,index: any) {
      api
          .get("/M/Discover/VideoRealtime/" + id, {
            params: {
              recIndex: index,
              vip: vip,
            },
          })
          .then((res) => {
            if (res.data.success == false) {
              if(res.data.msg =='请先登录再执行此操作'){
                Toast({
                  type: "error",
                  title: res.data.msg,
                  onClose: function () {
                    router.push({
                      name: "Login",
                      query: {
                        redirect: router.currentRoute.value.fullPath,
                      },
                    });
                  }
                });
              }else{
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            }else{
              const data = res.data.obj;
              shareTitle.value = data.course.courseName;//安卓获取的分享title
              document.title =data.course.courseName + "-北纳生物";//苹果获取的分享title
              route.meta.content.description = data.course.courseDescription;

              errorMsgStr.value = data.errorMsg;
              if(data.errorMsg=="NO_RECORD"){
                Toast({
                  type: "error",
                  title: "暂无回放",
                });
              }
              // liveProductSwitch.value = data.liveProductSwitch
              liveProductSwitch.value = data.course.liveType
              if (data.course.liveType == 0) {
                liveSwitchTab.value = false
                if (data.live.liveStatus==1) {
                  menuBack.value = ["课程概述", "回放目录(0)", "聊天"];
                } else {
                  menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
                  menuBack.value[2] = "评论(" + data.listComment.length + ")";
                }
              } else {
                liveSwitchTab.value = true
                if(data.course.liveInvitationOpen == 1){
                  menu.value = ["介绍", "聊天", "公告", "抢购", "邀请榜"];
                }else{
                  menu.value = ["介绍", "聊天", "公告", "抢购"];
                }
                menuBack.value = ["课程概述", "回放目录(0)", "评论(0)"];
              }
              if(data.course.liveAward){
                data.course.liveAward = data.course.liveAward.replace(/\n/g,"<br/>").replace(/\s/g, "&nbsp;");
              }
              intro.value = data.course;
              detail.value = data;
              courseChapterList.value = data.courseChapterList;
              live.value = data.live || '';
              uid.value = data.currentUser && data.currentUser.id;
              RecList.value = data.recList;
              menuBack.value[1] = "回放目录(" + data.recList.length + ")";
              listComment.value = data.listComment;

              nextTick(() => {
                asyncLoadJs("js/jquery-3.1.1.min.js", "videoRealtime").then(() => {
                  if (data.live.liveStatus==3) {
                    asyncLoadJs("js/playbackSDK.js", "videoRealtime").then(() => {
                      asyncLoadJs("js/livePlayBack.js", "videoRealtime");
                    });
                  } else {
                    asyncLoadJs("js/base.js", "videoRealtime").then(() => {
                      asyncLoadJs("js/liveSDK.JS", "videoRealtime").then(() => {
                        asyncLoadJs("js/videoRealtime.js", "videoRealtime");
                      });
                    });
                  }
                });
                leftTime(data.course.liveStartTime, function (d: any) {
                  if (d.status) {
                    const $dateShow1 = $("#dateShow");
                    $dateShow1.find(".d").html(d.d);
                    $dateShow1.find(".h").html(d.h);
                    $dateShow1.find(".m").html(d.m);
                    $dateShow1.find(".s").html(d.s);
                  }
                })
              });

            }

            followTimer = setTimeout(() => {
              if (!res.data.obj.currentUser || res.data.obj.currentUser == 'null') {
                Modal({
                  title: "温馨提示",
                  optionControl:1,
                  closeText:"关闭",
                  content: `
                <div>添加助教微信，领取直播福利</div>
                <div style="height: 120px;">
                  <div style="text-align: center;width: 98%;">
                    <img width="140px" src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/7b8730f9d78142c5a1b8efe2c048064d.jpg" alt="">
                  </div>
                </div>
                `,
                  dangerouslyUseHTMLString: true,
                });
              }
            }, 10000)
          })
          .catch((err) => {
            // console.log(err);
          });
    }
    getRenderData(id.value,recIndex)
    function toLogin() {
      router.push({
        path: "/login",
        query: {
          redirect: router.currentRoute.value.fullPath
        }
      });
    }
    function contrast(startDate: string) {
      return new Date().getTime() - new Date(timeFormat(startDate)).getTime() < 0
          ? true
          : false;
    }
    function followClick(id: any) {

      if(this.showCode==true) {
        this.showCode = false;
        this.showText = '已预约';
        api.get("/M/Discover/followLive", {
          params: {
            id: (intro.value as any).id
          },
        }).then((res) => {
          const data = res.data
          if(data.msg == '用户未登录') {
            Toast({
              title: '请先登录',
              type: "error",
            });
            router.push({
              path: "/login"
            });
          }
          if (res.data.success){
            Toast({
              type: "success",
              title: "预约成功！",
            });
          }
        })
      } else{
        this.showCode=true;
        this.showText='预约提醒';


        api.get("/M/Discover/cancelfollowLive", {
          params: {
            id: (intro.value as any).id
          },
        }).then((res) => {
          const data = res.data
          if(data.msg == '用户未登录') {
            Toast({
              title: '请先登录',
              type: "error",
            });
            router.push({
              path: "/login"
            });
          }
          if (res.data.success){
            Toast({
              type: "success",
              title: "已取消预约",
            });
          }
        })
      }
      // this.showCode = !this.showCode;
    }
    //  切换列表菜单
    // const menu = ref(["直播介绍", "互动聊天", "提问", "邀请栏", "公告", "商品栏"]);
    const itemBool = ref(0);
    const itemBoolBack = ref(0)
    function selectMenu(item: string) {
      if (item == "介绍") {
        itemBool.value = 0;
      }
      if (item.indexOf("聊天") != -1) {
        itemBool.value = 1;
      }
      // if (item == "提问") {
      //   itemBool.value = 2;
      // }
      if (item == "邀请榜") {
        itemBool.value = 4;

        api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
          id: (intro.value as any).id
        }))
            .then((res) => {
              inviteList.data = res.data.data
            })
            .catch((err) => {
              console.log(err);
            });
        codeImg.value = "/M/Server/createPoster?id=" + (intro.value as any).id+"&uid="+uid.value;
      }
      if (item == "公告") {
        itemBool.value = 2;
        $('.five-dot i').hide()
      }
      if (item == "抢购") {
        itemBool.value = 3;

        api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
          id: (intro.value as any).id
        }))
            .then((res) => {
              // res.data.obj.forEach((item: any) => {
              //   plist.push(item);
              // });
              if(res.data.type == '1'){
                applyProductOpen.value = true
              }else{
                applyProductOpen.value = false
              }
              console.log("applyProductOpen1",applyProductOpen.value)
              plist.data = res.data.obj
            })
            .catch((err) => {
              // console.log(err);
            });

      }

      // 广播通知位置修改
      if (itemBool.value == 1) {
        if ($(".vrt-radio-list").attr('style') == 'display: block;') {
          nextTick(() => {
            const vrtRadioHeight = $('.vrt-radio-list').outerHeight()
            $('.vrt-talk-list-ul').css('padding-top', ((vrtRadioHeight as any)+10)+'px')
          })
        }
      } else {
        $('.vrt-talk-list-ul').removeAttr('style')
      }
    }
    function selectMenuBack(item: string) {
      if (item == "课程概述") {
        itemBoolBack.value = 0;
      }
      if (item.indexOf("回放目录") != -1) {
        itemBoolBack.value = 1;
      }
      if (item.indexOf("评论") != -1) {
        itemBoolBack.value = 2;
      }
      if (item.indexOf("聊天") != -1) {
        itemBoolBack.value = 3;
      }
    }

    onMounted(() => {
      //获取进入直播间订阅状态
      api.get("/M/Discover/findFollow",{
        params: {
          id: id.value
        },
      }).then((res) => {
        if (res.data.success){
          const data = res.data;
          isFollow.value=data.obj.isfollow;
          // console.log("isfollow"+isFollow.value);
          if(isFollow.value){
            showText.value='已订阅'
            showCode.value = false
          }
        }
      });
      new Swiper("#menu-nav-swiper", {
        initialSlide: 0,
        slidesPerView: 5,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        on: {
          observerUpdate: function () {
            this.slideTo(0);
          },

        },
        // onSlideChangeEnd: function(){
        //   }
      });


    })


    onUnmounted(() => {
      removeJs("jquery-3.1.1.min.js")
      removeJs("base.js")
      removeJs("liveSDK.JS")
      removeJs("videoRealtime.js")
      removeJs("playbackSDK.js")
      removeJs("livePlayBack.js");

      removeJs("socket.io.js")
      removeJs("swfobject.js")
      removeJs("json3.min.js")
      removeJs("report.js")
      removeJs("dpc.js")
      //销毁当前页面的倒计时器
      clearTime();
    })


    const { toClipboard } = useClipboard()
    const copy = async (Msg: any) => {
      try {
        //复制
        await toClipboard(Msg)
        // console.log(Msg)
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
      } catch (e) {
        //复制失败
        // console.error(e)
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
      }
    }
    function clickBtn(){

      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {
        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))
            .then((res) => {
              if (!res.data.success) {
                Toast({
                  type: "error",
                  title: res.data.msg
                });
              }
              copy(res.data.obj)
            })
            .catch((err) => {
              // console.log(err);
            });
      }
    }

    function handleCopy() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后即可邀请，立刻前往",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
        });
        return false;
      } else {

        codeImg.value = "/M/Server/createPoster?id=" + (intro.value as any).id+"&uid="+uid.value;
        visiblePic.value = true

        api.post("/M/CourseServer/CreateLiveInvite", qs.stringify({
          id: (intro.value as any).id
        }))
            // api.post("/M/Server/createPoster", qs.stringify({
            //   id: (intro.value as any).id,
            //   uid: uid.value
            // }))
            .then((res) => {
              // if (!res.data.success) {
              //   Toast({
              //     type: "error",
              //     title: res.data.msg
              //   });
              // } else {
              //   visible.value = true
              //   urlData.value = res.data.obj
              // }
            })
            .catch((err) => {
              // console.log(err);
            });
      }
    }

    function confirm() {
      const clipboard = new Clipboard('.confirm-copy');
      clipboard.on('success', (e) => {
        Toast({
          type: "success",
          title: "复制链接成功，邀请好友进入直播间并登录，视为邀请成功"
        });
        // 释放内存
        visible.value = false
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        Toast({
          type: "error",
          title: "链接拷贝失败"
        });
        // 释放内存
        clipboard.destroy()
      })
    }
    function close() {
      visible.value = false
    }
    function closePic() {
      visiblePic.value = false
    }



    watch(
        () => {
          return itemBool.value
        },
        (newValue) => {
          //如果当前直播状态是已结束就，不定时刷新抢购产品
          if (newValue == 3 && (live.value as any).liveStatus != 3) {
            $('.five-dot i').hide()
            timer = setInterval(() => {
              api.post("/M/CourseServer/GetLiveProductList", qs.stringify({
                id: (intro.value as any).id
              }))
                  .then((res) => {
                    // console.log("444444",res)
                    // console.log("555555555555555",res.data);
                    if(res.data.type == '1'){
                      applyProductOpen.value = true;
                    }else{
                      applyProductOpen.value = false;
                    }
                    // console.log("applyProductOpen",applyProductOpen)
                    // console.log("66666666");
                    for(let i=0;i<=plist.data.length;i++) {
                      // console.log("777777777777");
                      if (res.data.obj[i].type != (plist.data[i] as any).type) {
                        (plist.data[i] as any).type = res.data.obj[i].type
                      }
                      if (res.data.obj[i].stock != (plist.data[i] as any).showStock) {
                        (plist.data[i] as any).showStock = res.data.obj[i].showStock
                      }
                    }

                  })
                  .catch((err) => {
                    // console.log(err);
                  });
            }, 10000);
          } else {
            clearInterval(timer)
            plist.data = []
          }

          if (newValue == 4) {
            inviteTimer = setInterval(() => {
              api.post("/M/CourseServer/GetLiveInviteList", qs.stringify({
                id: (intro.value as any).id
              }))
                  .then((res) => {
                    inviteList.data = res.data.data
                  })
                  .catch((err) => {
                    // console.log(err);
                  });
            }, 2000);
          } else {
            clearInterval(inviteTimer)
            // inviteList.data = []
          }
        }
    )

    // 定时轮训是否显示红包雨
    const redRain = setInterval(() => {
      api.post("/M/CourseServer/GetLiveOpenList", qs.stringify({
        id: (intro.value as any).id
      }))
          .then((res) => {
            if (res.data.obj.liveRedPacket) {
              liveRedPacket.value = true
            } else {
              liveRedPacket.value = false
            }
          })
          .catch((err) => {
            // console.log(err);
          });
    }, 10000);

    // 直播跳转红包
    function liveJumpRed() {
      const token = localStorage.getItem("token");
      if (!token) {
        Modal({
          title: "温馨提示",
          content: "登录后可参与，前往登录",
          confirmText: '登录',
          onConfirm: () => {
            router.push({
              name: "Login",
              query: {
                redirect: router.currentRoute.value.fullPath,
              },
            });
          },
          onClose: () => {
            // console.log('1')
          },
        });
        return false;
      }

      const fData = new FormData();
      fData.append("returnurl", 'http://m.bncc.com/videored?courseId='+(intro.value as any).id);
      api
          .post("/M/Event/redPacketWeChatUrl", fData)
          .then((res) => {
            if (res.data.success) {
              window.location.href = res.data.returnUrl;
            } else {
              Toast({
                title: res.data.msg,
                type: "error",
              });
            }
          })
          .catch(() => {
            // console.log('false false')
          });
    }

    watch(
        [
          () => {
            return shareTitle.value;
          }
        ],
        (to, from) => {
          if (from && from[0] == 'videoRealtime') {
            clearInterval(inviteTimer)
            clearInterval(timer)
            clearInterval(redRain)
            clearTimeout(followTimer)
          }
          const isweixin = ref(false);
          const ua = navigator.userAgent.toLowerCase();
          const reg = /MicroMessenger/i;
          isweixin.value = reg.test(ua);
          if (isweixin.value) {
            nextTick(() => {
              const link = window.location.href;
              const formData = new FormData();
              formData.append("url", link);
              axios.post("/M/Server/getweixin", formData).then((res: any) => {
                const data = res.data;
                wx.config({
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: data.appid, // 必填，公众号的唯一标识
                  timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                  nonceStr: data.nonceStr, // 必填，生成签名的随机串
                  signature: data.str, // 必填，签名
                  jsApiList: [
                    "checkJsApi",
                    "updateAppMessageShareData",
                    "updateTimelineShareData",
                  ], // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                  //需在用户可能点击分享按钮前就先调用

                  wx.updateAppMessageShareData({
                    title: to+'-北纳生物', // 分享标题
                    desc: route.meta.content.description, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                    success: function () {
                      // 设置成功
                    },
                  });
                  wx.updateTimelineShareData({
                    title: to+'-北纳生物', // 分享标题
                    desc: route.meta.content.description, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                    success: function () {
                      // 设置成功
                    },
                  });
                });
              });
            });
          }
        }
    );
    watch(
        [
          () => {
            return route.name;
          }
        ],
        (to, from) => {
          if (from && from[0] == 'videoRealtime') {
            clearInterval(inviteTimer)
            clearInterval(timer)
            clearInterval(redRain)
            clearTimeout(followTimer)
          }
        }
    );
    watch(
        () => {
          return liveProductSwitch.value
        },
        (newValue) => {
          if (newValue == 0) {
            //newValue == 0 表示研讨会
            clearInterval(inviteTimer)
            clearInterval(timer)
            clearInterval(redRain)
            // clearTimeout(followTimer)
          }
        }
    )
    watch(
        () => {
          return menu.value
        },
        () => {
          new Swiper("#menu-nav-swiper", {
            initialSlide: 0,
            slidesPerView: 5,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },
            },
          });
        }
    )
    watch(
        () => {
          return menuBack.value
        },
        () => {
          new Swiper("#menu-nav-swiper", {
            initialSlide: 0,
            slidesPerView: 5,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },
            },
          });
        }
    )
    watch(
        [
          () => {
            return route.query;
          },
          () => {
            return route.params;
          },
        ],
        (to, from) => {
          // 避免登录时触发页面刷新
          const index=String(to[0].recIndex)
          if (to[0].id || index) {
            id.value = to[0].id;
            getRenderData(to[0].id as string,index as any);

          }
        }
    );
    function subComment() {
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        id,
        content: comment.value,
        type: 0
      });
      axios
          .post("/M/Server/DoCourseComment", data)
          .then((res) => {
            if (res.data.success) {
              Toast({
                type: "success",
                title: res.data.msg,
              });
              (listComment.value as object[]).unshift({
                ...res.data.obj,
                isAlreadyZan: 0,
                user: {
                  ...user,
                  userImg: (user as any).userImg,
                },
              });
              menuBack.value[2] = "评论(" + (listComment.value as object[]).length + ")";
              comment.value = "";
            } else {
              Toast({
                type: "error",
                title: res.data.msg,
              });
              router.push({name: "Login"})
            }
          })
          .catch((err) => {

            const message = err.message ? err.message : "未知错误，请联系管理员";
            Toast({
              type: "error",
              title: message,
            });
          });
    }
    if (location.href.indexOf("reloaded") == -1) {
      location.href = location.href + "#reloaded";
      // alert(location.href)
      // location.href =location.href.replace('reloaded',"")
      setTimeout(function () {
        location.reload();
      },1000)

    }
    return {
      contrast,
      toLogin,
      intro,
      uid,
      courseChapterList,
      live,
      menu,
      menuBack,
      selectMenu,
      selectMenuBack,
      itemBool,
      itemBoolBack,
      detail,
      plist,
      applyProductOpen,
      clickBtn,
      liveJumpRed,
      liveRedPacket,
      inviteList,
      liveSwitchTab,
      handleCopy,
      urlData,
      confirm,
      close,
      visible,
      visiblePic,
      codeImg,
      closePic,
      RecList,
      dateFormat,
      listComment,
      comment,
      errorMsgStr,
      showCode,
      followClick,
      showText,
      subComment
      // openGifticon,
      // openFaceicon
    };
  },
});

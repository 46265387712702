import { render } from "./VideoRealtime.vue?vue&type=template&id=9c0d2fbc&scoped=true"
import script from "./VideoRealtime.vue?vue&type=script&lang=ts"
export * from "./VideoRealtime.vue?vue&type=script&lang=ts"

import ".././assets/styles/videoRealtime.css?vue&type=style&index=0&lang=css"
import "./VideoRealtime.vue?vue&type=style&index=1&id=9c0d2fbc&lang=scss&scoped=true"
import "./VideoRealtime.vue?vue&type=style&index=2&id=9c0d2fbc&lang=css"
script.render = render
script.__scopeId = "data-v-9c0d2fbc"

export default script

import {defineComponent, ref, isReactive, reactive, inject, watch} from "vue";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import {useRouter} from "vue-router";
import qs from "qs";
import Modal from "@/components/UI/Modal";

export default defineComponent({
  data() {
    return {
      rushMessage: '',
      rushList: [],
      showRush: true
    }
  },
  props: {
    list: Object,
    id: {
      type: String,
      default: "",
    },
    applyProductOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showModal = ref(false);
    const userInfo = inject("userInfo") as any;
    const router = useRouter();
    function closeModal() {
      showModal.value = false
    }

    function addShopCarOver(id: string, livePid: string) {
      this.rushMessage = '已经被抢完了，下次请快点哦';
      showModal.value = true;
      api.post("/M/CourseServer/getLiveProductRecord", qs.stringify({courseId:id})).then((res) => {
        //获取记录
        if(res.data.data.length===0){
          //若没有记录则切换显示无抢购记录
          this.showRush=false
        }else {
          this.showRush=true;
          this.rushList=[];
          res.data.data.map((item: { userName: string; productName: string })=>{
            const obj={userName:'',productName:''};
            obj.userName=item.userName.substr(0, 3) + '****' + item.userName.substr(7);
            obj.productName=item.productName;
            this.rushList.push(obj)
          });
          this.rushList.sort()
        }
      }).catch((err) => {
        Toast({
          type: "error",
          title: err
        });
      });



    }
    function addShopCar(id: string, livePid: string) {
      //先清空提示语，防止弹窗出现上一次提示语
      this.rushMessage='';
          api.post("/M/CourseServer/addLiveProduct", qs.stringify({
            id,
            livePid
          })).then((res) => {
            if (res.data.msg == '请先登录再执行此操作') {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            } else {
              //获取提示语
              this.rushMessage = res.data.msg;
              showModal.value = true;
              // eslint-disable-next-line @typescript-eslint/no-this-alias
              const that=this
              setTimeout(function () {
                api.post("/M/CourseServer/getLiveProductRecord", qs.stringify({courseId:id})).then((res) => {
                  //获取记录
                  console.log(res.data,66666666666)
                  if(res.data.data.length===0){
                    //若没有记录则切换显示无抢购记录
                    that.showRush=false
                  }else {
                    that.showRush=true;
                    that.rushList=[];
                    res.data.data.map((item: { userName: string; productName: string })=>{
                      const obj={userName:'',productName:''};
                      obj.userName=item.userName.substr(0, 3) + '****' + item.userName.substr(7);
                      obj.productName=item.productName;
                      that.rushList.push(obj)

                    });
                    // console.log(that.rushList,888888888)
                  }
                }).catch((err) => {
                  Toast({
                    type: "error",
                    title: err
                  });
                });
              },1000)



            }
          })
              .catch((err) => {
                Toast({
                  type: "error",
                  title: err
                });
              });
    }
    function addApply(id: string, livePid: string) {

          api.post("/M/CourseServer/toApplyProduct", qs.stringify({
            id,
            livePid
          })).then((res) => {
            if (res.data.msg == '请先登录再执行此操作') {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            } else {
              Toast({
                type: "success",
                title: res.data.msg
              });

            }
          })
          .catch((err) => {
            Toast({
              type: "error",
              title: err
            });
          });
    }
    function unableShop() {
      Toast({
        type: "error",
        title: '抢购暂未开启~'
      });
    }

    function back() {
      router.go(-1);
    }

    function openDesc(index: any) {
      (props.list as any).data[index].productDesc = true
    }
    function closeDesc(index: any) {
      (props.list as any).data[index].productDesc = false
    }

    return {
      addShopCar,
      addShopCarOver,
      addApply,
      showModal,
      closeModal,
      userInfo,
      back,
      unableShop
    };
  },
});
